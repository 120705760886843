const colors = {
    primary: '#e11b22',
    primarySelect: '#921116',
    disabled: '#F9B0B3',
    secondary: '#BE4B4F',
    secondarySelect: '#993C40',
    tertiary: '#FBD2D3',
};

export default {
    colors,
};
